@import "bootstrap";
@import "font-awesome";
@import "slick";

.black {
	color: black
}

@font-face {
	font-family: 'quarkbold';
	src: url('../fonts/quark-bold.eot');
	src: url('../fonts/quark-bold?#iefix') format('embedded-opentype'),
		 url('../fonts/quark-bold.woff') format('woff'),
		 url('../fonts/quark-bold.ttf') format('truetype'),
		 url('../fonts/quark-bold.svg#quarkbold') format('svg');
	font-weight: normal;
	font-style: normal;

}

@font-face {
	font-family: 'quarklight';
	src: url('../fonts/quark-light/quark-light-webfont.eot');
	src: url('../fonts/quark-light/quark-light-webfont.eot?#iefix') format('embedded-opentype'),
		 url('../fonts/quark-light/quark-light-webfont.woff2') format('woff2'),
		 url('../fonts/quark-light/quark-light-webfont.woff') format('woff'),
		 url('../fonts/quark-light/quark-light-webfont.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}


body {
	font-family: 'quarklight';
	line-height: 1.782;
	background-color: #fff;
	color: #5c5c5c;
	position: relative;
}

a {
	color: #fff;
}
a:focus, a:hover{
	color: #295A54;
}
.btn:hover, .btn:focus {
	color: #295A54;
	border-color: #295A54;
}


/*Nav bar*/
.navbar{
	margin-bottom:0;
	min-height: 56px;
	border-radius: 0;
}
.navbar-default{
	// background-color: #2a2a2a;
	border-color: transparent;
}
.navbar-default .navbar-brand,.navbar-default .navbar-nav > li > a {
	color: #000;
	// color: #fff;
	font-size: 20px;
}
.navbar-brand img{
	max-width: 200px;
	max-height: 60px;
}
.navbar-brand{
	padding: 12px 15px;
}
.navbar-default .navbar-nav>li>a:focus, .navbar-default .navbar-nav>li>a:hover{
	color: #30BEAC;
}
@media (min-width: 768px){
	.navbar-nav>li>a {
		padding-top: 20px;
	}
}


.hero{
	background:url(../images/intro-bg.jpg) no-repeat center;
	background-size:cover;
	border:none;
	min-height: 720px;
	text-align:center;
	padding-right:30px;
}

@media (min-width:992px) {
	.hero{
		margin-top:50px;
	}
}

@media (max-width:992px) {
	.hero{
		margin-top:45px;
	}
	.hero h1{
		margin-top:85px;
	}
}

.hero .get-it h1, .hero .get-it p{
	color:#fff;
	text-shadow:2px 2px 3px rgba(0,0,0,0.3);
	margin-bottom:40px;
}

.hero .get-it .btn{
	margin-left:10px;
	margin-bottom:10px;
	text-shadow:none;
}

div.iphone-mockup{
	position:relative;
	max-width:250px;
	margin:20px;
	display:inline-block;
}

.iphone-mockup img.device{
	width:100%;
	height:auto;
}

.iphone-mockup .screen{
	position:absolute;
	width:88%;
	height:77%;
	top:12%;
	border-radius:2px;
	left:6%;
	border:1px solid #444;
	background-color:#aaa;
	overflow:hidden;
	background:url(../../assets/img/screen-content-iphone-6.jpg);
	background-size:cover;
	background-position:center;
}

.iphone-mockup .screen:before{
	content:'';
	background-color:#fff;
	position:absolute;
	width:70%;
	height:140%;
	top:-12%;
	right:-60%;
	transform:rotate(-19deg);
	opacity:0.2;
}

.icon-feature{
	text-align:center;
}

.icon-feature .glyphicon{
	font-size:60px;
}

section p {
	font-size: 20px;
}
section.features{
	background-color:#369;
	padding:40px 0;
	color:#fff;
}

section.contact{
	background-color:#1c1c1c;
	padding:100px 0;
	color:#fff;
	font-size:20px;
}

.features h2{
	color:#fff;
}

.features .icon-features{
	margin-top:15px;
}

.aboutus blockquote{
	text-align:center;
}

section.aboutus{
	margin:50px 0;
	min-height: 260px;
}

.ourservices blockquote{
	text-align:center;
}

section.ourservices{
	margin:50px 0;
	min-height: 260px;
	background-color:#efefef;
	padding:40px 0;
	color:#000;
}

.ourservices h2{
	color:#000;
}

.portfolio blockquote{
	text-align:center;
}

section.portfolio{
	margin:50px 0;
	min-height: 260px;
}


.site-footer{
	padding:20px 0;
	text-align:center;
}

@media (min-width:768px) {
	.site-footer h5{
		text-align:left;
	}
}

.site-footer h5{
	color:inherit;
	font-size:20px;
}

.site-footer .social-icons a:hover{
	opacity:1;
}

.site-footer .social-icons a{
	display:inline-block;
	width:32px;
	border:none;
	font-size:20px;
	border-radius:50%;
	margin:4px;
	color:#fff;
	text-align:center;
	background-color:#798FA5;
	height:32px;
	opacity:0.8;
	line-height:32px;
}

@media (min-width:768px) {
	.site-footer .social-icons{
		text-align:right;
	}
}

.thumbnail {
	min-height: 510px
}
.thumbnail img {
	text-align: center;
	margin-top: 20px;
}

.thumbnail p {
	font-size: 20px;
	text-align: center;
}

.telno {
	visibility: visible;
	animation-name: bounceIn;
}

.mailto {
	visibility: visible;
	animation-delay: 0.1s;
	animation-name: bounceIn;
}
